import type { NamedLogger } from "@repo/logger";
import type { AuthenticatedIdentity } from "../types";
import type {
  AuthenticatedIdentityEvent,
  CreatingUserEvent,
  DetectedStoredCredentialsEvent,
  DetectingStoredCredentialsEvent,
  RefusedIdentityEvent,
  SigningInEvent,
  SigningOutEvent,
} from "./identityEvents";

/**
 * identityEventFactory creates events for the identity machine.
 * @param loggerDep
 */
export const identityEventFactory = (loggerDep: NamedLogger) => {
  const logger = loggerDep.child("eventFactory");
  return {
    newDetectingEvent: (): DetectingStoredCredentialsEvent => {
      const type = "identity.detecting";
      if (_LOG) logger.info(type);
      return { type };
    },
    newDetectedEvent: (
      identity: AuthenticatedIdentity,
    ): DetectedStoredCredentialsEvent => {
      const type = "identity.detected";
      if (_LOG) logger.info(type, { identity });
      return { type, identity };
    },
    newSigningInEvent: (token: string): SigningInEvent => {
      const type = "identity.signingIn";
      if (_LOG) logger.info(type, { token: !!token }); // don't log token
      return { type };
    },
    newAuthenticatedEvent: (
      identity: AuthenticatedIdentity,
    ): AuthenticatedIdentityEvent => {
      const type = "identity.authenticated";
      if (_LOG) logger.info(type, { identity });
      return { type, identity };
    },
    newRefusedEvent: (error: string): RefusedIdentityEvent => {
      const type = "identity.refused";
      if (_LOG) logger.info(type, { error });
      return { type, error };
    },
    newSigningOutEvent: (): SigningOutEvent => {
      const type = "identity.signingOut";
      if (_LOG) logger.info(type);
      return {
        type,
      };
    },
  };
};

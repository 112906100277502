import { type Logger, Named } from "@repo/logger";
import { assign, setup } from "xstate";
import {
  GUEST_IDENTITY,
  type IdentityStore,
  isAuthenticatedIdentity,
} from "~/domains/identity/types";

import type { IdentityEvents } from "./identityEvents";

const initialContext = (): IdentityStore => ({
  identity: GUEST_IDENTITY,
  error: "",
});

export enum IdentityStates {
  Start = "start",
  Detecting = "detecting",
  Guest = "guest",
  SigningIn = "signingIn",
  SigningOut = "signingOut",
  Authenticated = "authenticated",
  SyncWithMetadataAPI = "syncWithMetadataAPI",
}

/**
 * newIdentityMachine creates a new identity machine.
 * @param depLogger
 */
export const newIdentityMachine = (depLogger: Logger) => {
  const logger = new Named(depLogger, "identityMachine");
  return setup({
    types: {
      context: {} as IdentityStore,
      events: {} as IdentityEvents,
    },
  }).createMachine({
    id: "identityMachine",
    initial: "start",
    context: initialContext(),
    states: {
      start: {
        on: {
          "identity.detecting": {
            target: "detecting",
          },
          "identity.authenticated": [
            {
              guard: ({ event }) => isAuthenticatedIdentity(event.identity),
              target: "authenticated",
              actions: [
                () => {
                  if (_LOG)
                    logger.info("detecting(authenticated) is authenticated");
                },
                assign({
                  identity: ({ event }) => event.identity,
                  error: undefined,
                }),
              ],
            },
          ],
        },
      },
      detecting: {
        on: {
          "identity.authenticated": [
            {
              guard: ({ event }) => isAuthenticatedIdentity(event.identity),
              target: "authenticated",
              actions: [
                () => {
                  if (_LOG)
                    logger.info("detecting(authenticated) is authenticated");
                },
                assign({
                  identity: ({ event }) => event.identity,
                  error: undefined,
                }),
              ],
            },
            {
              target: "guest",
              actions: [
                () => {
                  if (_LOG) logger.info("detecting(authenticated) is guest");
                },
              ],
            },
          ],
          "identity.signingOut": {
            target: "signingOut",
          },
        },
      },
      guest: {
        on: {
          "identity.signingIn": {
            target: "signingIn",
          },
          "identity.authenticated": [
            {
              guard: ({ event }) => isAuthenticatedIdentity(event.identity),
              target: "authenticated",
              actions: [
                () => {
                  if (_LOG)
                    logger.info("detecting(authenticated) is authenticated");
                },
                assign({
                  identity: ({ event }) => event.identity,
                  error: undefined,
                }),
              ],
            },
            {
              target: "guest",
              actions: [
                () => {
                  if (_LOG) logger.info("detecting(authenticated) is guest");
                },
              ],
            },
          ],
        },
      },
      signingIn: {
        on: {
          "identity.authenticated": [
            {
              guard: ({ event }) => isAuthenticatedIdentity(event.identity),
              target: "signingIn",
            },
            {
              target: "guest",
            },
          ],
          "identity.refused": {
            target: "guest",
            actions: assign({
              error: ({ event }) => event.error,
              identity: GUEST_IDENTITY,
            }),
          },
        },
      },
      signingOut: {
        always: {
          target: "guest",
          actions: assign({
            identity: GUEST_IDENTITY,
            error: undefined,
          }),
        },
      },
      authenticated: {
        on: {
          "identity.signingOut": {
            target: "signingOut",
          },
          // Allow authenticating from guest to non-guest when upgrading anonymous users to non-anonymous
          "identity.authenticated": [
            {
              guard: ({ event, context }) =>
                context.identity.isGuest &&
                isAuthenticatedIdentity(event.identity),
              target: "authenticated",
              actions: [
                () => {
                  if (_LOG)
                    logger.info("detecting(authenticated) is authenticated");
                },
                assign({
                  identity: ({ event }) => event.identity,
                  error: undefined,
                }),
              ],
            },
          ],
        },
      },
    },
  });
};

export const getPullRequestId = () => {
  return stripLeadingTrailingQuotes(process.env.VERCEL_GIT_PULL_REQUEST_ID)
}
export const getVercelPullRequestId = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_VERCEL_GIT_PULL_REQUEST_ID)
}
export const getApiTarget = () => {
  return stripLeadingTrailingQuotes(process.env.STORYTELL_API_TARGET)
}
export const getGitHash = () => {
  return process.env.VERCEL_GIT_COMMIT_SHA ? stripLeadingTrailingQuotes(process.env.VERCEL_GIT_COMMIT_SHA) : "unknown"
}
export const getFirebaseAPIKey = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_API_KEY)
}
export const getFirebaseAuthDomain = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_AUTH_DOMAIN)
}
export const getFirebaseProjectId = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_PROJECT_ID)
}
export const getFirebaseStorageBucket = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_STORAGE_BUCKET)
}
export const getFirebaseMessagingSenderId = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_MESSAGING_SENDER_ID)
}
export const getFirebaseAppId = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_FIREBASE_APP_ID)
}
export const getVercelEnv = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_VERCEL_ENV);
}
export const getVercelURL = () => {
  return stripLeadingTrailingQuotes(process.env.VERCEL_URL);
}
export const getVercelBranch = () => {
  return stripLeadingTrailingQuotes(process.env.VITE_VERCEL_BRANCH_URL);
}
export const getGitCommitRef = () => {
  return stripLeadingTrailingQuotes(process.env.VERCEL_GIT_COMMIT_REF);
}
export const getVercelCommitSHA = () => {
  return stripLeadingTrailingQuotes(process.env.VERCEL_GIT_COMMIT_SHA);
}
export const getVercelCommitMessage = () => {
  return stripLeadingTrailingQuotes(process.env.VERCEL_GIT_COMMIT_MESSAGE);
}
const stripLeadingTrailingQuotes = (str?: string) => {
  return (!str) ? "" : str.replace(/^"|"$/g, "")
}

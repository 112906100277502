import Client, { Local, Environment, PreviewEnv } from "./encore.gen";

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Changes may need to be applied to Vercel.
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

type Targets = "development" | "preview" | "production" | "staging" | "local";

// biome-ignore lint/suspicious/noExplicitAny: typeguard
const isTargets = (value: any): value is Targets => {
  return (
    value === "development" ||
    value === "preview" ||
    value === "production" ||
    value === "local" ||
    value === "staging"
  );
};

const getTarget = (target: string, pr?: string) => {
  // Local Development
  const isTarget = isTargets(target);
  if (!isTarget) {
    console.log("refusing to use target:", target);
    return Environment("production");
  }
  // Ephemeral Preview Environments
  if (target === "preview" && pr) {
    return PreviewEnv(pr);
  }
  // Durable Environments
  switch (target) {
    case "development":
      return Environment("development");
    case "staging":
      return Environment("staging");
    case "local":
      return Local;
    default:
      return Environment("production");
  }
};

let clientTarget = Environment("production"); // Default to production

export const init = (target?: string, pr?: string) => {
  clientTarget = getTarget(target ?? "production", pr);
};

export const getClientTarget = () => clientTarget;

/**
 * Get the request client. This function is used to create a new client instance with the correct target.
 * The target is configured based on the environment variables. For more information, see `/docs/system/vercel.md`.
 * @param token
 */
export const getRequestClient = (token: () => string | undefined) => {
  return new Client(clientTarget, {
    auth: token,
    // requestInit: {
    //   headers: {
    //     "Access-Control-Allow-Origin": "application/json",
    //   },
    // }
  });
};

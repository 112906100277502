import {
  ThreadMessageKinds,
  type stid,
  type ThreadMessage,
  type ThreadMessageKind,
  type threads,
} from "@repo/client";
import type {
  CreateThreadEvent,
  ReceiveEvent,
  ResumeThreadEvent,
  SendPromptEvent,
  ThreadCreatedEvent,
  ThreadFailEvent,
  ThreadResetEvent,
  LoadedThreadEvent,
  LoadingThreadFailedEvent,
} from "./threadEvents";
import type { OnCreateThreadParams, Prompt } from "~/domains/threads/types";
import type { NamedLogger } from "@repo/logger";
import { newPromptWithDefaults } from "~/domains/threads/prompt/prompt";
import type { SendKnowledgeChangeEvent } from "./threadEvents";
import { decodeBase64URI } from "@repo/encoding";
import type { WorkingContext } from "~/domains/identity/types";
type Knowledge = threads.Knowledge;

export const threadEventFactory = (loggerDep: NamedLogger) => {
  const logger = loggerDep.child("threadEventFactory");
  const newSendPrompt = (message: Prompt): SendPromptEvent => {
    logger.info("send prompt", { message });
    return {
      type: "threads.sendPrompt",
      message,
    };
  };

  return {
    newSendKnowledgeChangeEvent: (
      knowledge: Knowledge,
    ): SendKnowledgeChangeEvent => {
      logger.info("send knowledge change", { knowledge });
      return {
        type: "threads.sendKnowledgeChange",
        kind: ThreadMessageKinds.MessageKindKnowledgeChangeV1,
        message: {
          // @ts-ignore - undefined as this should only be set by server
          messageId: undefined,
          kind: ThreadMessageKinds.MessageKindKnowledgeChangeV1,
          knowledge,
        },
      };
    },
    newSendPrompt,
    newSendPromptWithDefaults: (
      prompt: string,
      threadId: stid.ThreadStringID,
      transformationId?: string,
    ): SendPromptEvent => {
      return newSendPrompt(newPromptWithDefaults(prompt, threadId));
    },
    newFailedEvent: (errorMessage: string): ThreadFailEvent => ({
      type: "threads.failed",
      errorMessage,
    }),
    /**
     * Creates a new thread created event to inform the state machine of the new threadId. The messageKind and
     * messageContent should then be used to send the first message to the thread.
     * @param threadId
     * @param params
     */
    newThreadCreatedEvent: (
      threadId: stid.ThreadStringID,
      params: OnCreateThreadParams,
    ): ThreadCreatedEvent => {
      logger.info("thread created", { threadId });
      return {
        type: "threads.created",
        threadId,
        params,
      };
    },
    newResetEvent: (): ThreadResetEvent => {
      logger.info("resetting thread");
      return { type: "threads.reset" };
    },
    newThreadsReceiveMessageEvent: (message: ThreadMessage): ReceiveEvent => {
      return { type: "threads.receive", message };
    },
    newCreateThreadEvent: (params: OnCreateThreadParams): CreateThreadEvent => {
      logger.info("creating thread", params);
      return {
        type: "threads.create",
        onCreateThreadParams: params,
      };
    },
    newResumeThreadEvent: (threadId: string): ResumeThreadEvent => {
      logger.info("resuming thread", { threadId });
      return {
        type: "threads.resume",
        threadId,
      };
    },
    newLoadedThreadEvent: (thread: threads.ThreadState): LoadedThreadEvent => {
      logger.info("loaded thread", { thread });

      const messages: ThreadMessage[] = [];
      for (const message of thread.messages ?? []) {
        messages.push(JSON.parse(decodeBase64URI(message.content)));
      }

      return {
        type: "threads.loaded",
        label: thread.label,
        projectId: thread.projectId,
        messages,
        activeAssets: thread.activeAssets,
      };
    },
    LoadingThreadFailedEvent: (
      errorMessage: string,
    ): LoadingThreadFailedEvent => {
      logger.info("loading thread failed", { errorMessage });
      return {
        type: "threads.loadingFailed",
        errorMessage,
      };
    },
  };
};

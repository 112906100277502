import { assign, fromPromise, setup } from "xstate";
import type { ThreadStore } from "~/domains/threads/types";
import type { Logger } from "@repo/logger";
import type { ThreadEvents } from "./threadEvents";
import {
  Commands,
  encodeMessageCmdParams,
  ThreadMessageKinds,
  Recipient,
  type CommandKey,
  isMessageTextV1,
  isMessageKnowledgeV1,
  type ThreadMessage,
  type operations,
  type threads,
  type stid,
  isMessagePrompt,
} from "@repo/client";
import { decodeBase64URI, encodeBase64URI } from "@repo/encoding";
import { computeThreadData } from "./computeThreadData";
import type { KnowledgeService } from "~/domains/knowledge/service";
import type { IdentityService } from "~/domains/identity/service";

export const ThreadMachineState = {
  Idle: "idle",
  Creating: "creating",
  Ready: "ready",
  Failed: "failed",
};
export type ValueOf<T> = T[keyof T];
export type ThreadMachineStates = ValueOf<typeof ThreadMachineState>;

const initialThreadContext = (): ThreadStore => ({
  messages: [],
  activeAssets: [],
  threadId: null,
  projectId: null,
  label: "New",
  errorMessage: null,
  computed: {
    autosubmitCampaignPrompt: undefined,
    transformationCampaign: undefined,
  },
  queuedPrompt: undefined,
});

type Dependencies = {
  logger: Logger;
  createThread: (params: {
    label: string;
    projectId: string;
  }) => Promise<operations.Response<threads.ThreadState>>;
  loadThread: (
    threadId: stid.ThreadStringID,
  ) => Promise<operations.Response<threads.ThreadState>>;
  onSendServerWSMessage: (
    commandKey: CommandKey,
    recipients: string[],
    data: string,
  ) => void;
  knowledgeService: KnowledgeService;
  identityService: IdentityService;
};

const parseMessages = (m: threads.ThreadState["messages"]) => {
  const messages: ThreadMessage[] = [];
  for (const message of m ?? []) {
    messages.push(JSON.parse(decodeBase64URI(message.content)));
  }
  return messages;
};

export const useThreadMachine = (deps: Dependencies) => {
  const workingContext = () =>
    deps.identityService.snapshot.context.identity.workingContext;

  return setup({
    types: {
      context: {} as ThreadStore,
      events: {} as ThreadEvents,
    },
    actors: {
      loadThread: fromPromise(
        async ({ input }: { input: { threadId: stid.ThreadStringID } }) =>
          deps.loadThread(input.threadId),
      ),
      createThread: fromPromise(
        async ({
          input,
        }: {
          input: {
            label: string;
            projectId: string;
          };
        }) => deps.createThread(input),
      ),
    },
  }).createMachine({
    id: "threadMachine",
    initial: "idle",
    context: initialThreadContext(),
    on: {
      "threads.reset": {
        target: ".idle",
        actions: assign(initialThreadContext()),
      },
      "threads.resume": {
        target: ".loading",
        guard: ({ event }) => event.threadId.startsWith("thread"),
        actions: [
          assign({
            threadId: ({ event }) => event.threadId,
          }),
        ],
      },
    },
    states: {
      idle: {
        // When the machine is idle (no active thread) receiving a send prompt or send knowledge
        // event means we need to create a new thread first, so we queue up the message and then
        // go on to creating a prompt
        on: {
          "threads.sendPrompt": {
            target: "creating",
            guard: ({ context }) => context.threadId === null,
            actions: assign({
              queuedPrompt: ({ event }) => ({
                kind: event.message.kind,
                prompt: event.message,
                workingCtx: workingContext(),
              }),
            }),
          },
          "threads.sendKnowledgeChange": {
            target: "creating",
            guard: ({ context }) => context.threadId === null,
            actions: assign({
              queuedPrompt: ({ event }) => ({
                kind: ThreadMessageKinds.MessageKindKnowledgeChangeV1,
                knowledge: event.message,
                workingCtx: workingContext(),
              }),
            }),
          },
        },
      },
      loading: {
        invoke: [
          {
            src: "loadThread",
            input: ({ context }) => ({ threadId: context.threadId || "" }),
            onDone: {
              target: "ready",
              actions: [
                assign({
                  threadId: ({ event }) => event.output.data?.threadId,
                  messages: ({ event }) =>
                    parseMessages(event.output.data?.messages),
                  activeAssets: ({ event }) => event.output.data?.activeAssets,
                  label: ({ event }) => event.output.data?.label,
                  projectId: ({ event }) => event.output.data?.projectId,
                }),
                assign({
                  computed: ({ context }) =>
                    computeThreadData(context.messages),
                }),
              ],
            },
            onError: { target: "failed" },
          },
        ],
      },
      creating: {
        invoke: {
          src: "createThread",
          input: () => ({
            label: "New",
            projectId: workingContext().projectId,
          }),
          onDone: {
            target: "created",
            actions: assign({
              threadId: ({ event }) => event.output.data?.threadId,
              messages: [],
              activeAssets: ({ event }) => event.output.data?.activeAssets,
              label: ({ event }) => event.output.data?.label,
              projectId: ({ event }) => event.output.data?.projectId,
              computed: computeThreadData([]),
            }),
          },
          onError: {
            target: "failed",
          },
        },
      },
      created: {
        always: [
          {
            target: "ready",
            guard: ({ context }) => context.queuedPrompt === undefined,
          },
          {
            target: "sendingPrompt",
            guard: ({ context }) => isMessagePrompt(context.queuedPrompt),
          },
          {
            target: "sendingKnowledgeChange",
            guard: ({ context }) => isMessageKnowledgeV1(context.queuedPrompt),
          },
        ],
      },
      sendingPrompt: {
        always: {
          target: "ready",
          actions: [
            ({ context }) => {
              if (!context.threadId) {
                throw new Error(
                  "refusing to send prompt; threadId is not in machine context",
                );
              }
              if (
                !context.queuedPrompt ||
                !("prompt" in context.queuedPrompt)
              ) {
                throw new Error(
                  `context.queuedPrompt either empty or not a prompt type: ${context.queuedPrompt?.kind}`,
                );
              }
              const recipients = [Recipient.RecipientModel];

              const { added, removed } =
                context.queuedPrompt.prompt.knowledge.assetContext;
              if (added.length || removed.length) {
                const knowledge: threads.MessageKnowledgeV1 = {
                  messageId: "",
                  kind: ThreadMessageKinds.MessageKindKnowledgeChangeV1,
                  // @ts-expect-error
                  knowledgeFull: undefined,
                  knowledge: context.queuedPrompt.prompt.knowledge,
                  createdBy: "",
                  progress: {},
                };

                deps.onSendServerWSMessage(
                  Commands.ThreadMessageCmdKeyV1,
                  recipients,
                  encodeMessageCmdParams({
                    messageContent: encodeBase64URI(JSON.stringify(knowledge)),
                    messageKind:
                      ThreadMessageKinds.MessageKindKnowledgeChangeV1,
                    recipients,
                    threadId: context.threadId,
                  }),
                );
              }

              deps.onSendServerWSMessage(
                Commands.ThreadMessageCmdKeyV1,
                recipients,
                encodeMessageCmdParams({
                  messageContent: encodeBase64URI(
                    JSON.stringify(context.queuedPrompt.prompt),
                  ),
                  messageKind: context.queuedPrompt.kind,
                  recipients,
                  threadId: context.threadId,
                }),
              );
            },
            assign({
              queuedPrompt: undefined,
            }),
          ],
        },
      },
      sendingKnowledgeChange: {
        always: {
          target: "ready",
          actions: [
            assign({
              activeAssets: ({ context }) => {
                if (
                  !context.queuedPrompt ||
                  !("knowledge" in context.queuedPrompt)
                )
                  throw new Error("context.queuedPrompt is of wrong type");

                const message = context.queuedPrompt.knowledge;
                const { added, removed } = message.knowledge.assetContext;

                let active = context.activeAssets?.map((a) => a.id) || [];
                added.forEach((a) => {
                  if (!active.find((b) => b === a)) {
                    active.push(a);
                  }
                });

                removed.forEach((r) => {
                  active = active.filter((a) => a !== r);
                });

                return active
                  .map(
                    // biome-ignore lint/style/noNonNullAssertion: <explanation>
                    (a) => deps.knowledgeService.assetsCache.byId[a]!,
                  )
                  .filter((a) => !!a);
              },
            }),
            ({ context }) => {
              if (!context.threadId) {
                throw new Error(
                  "refusing to send knowledge; threadId is not in machine context",
                );
              }
              if (
                !context.queuedPrompt ||
                !("knowledge" in context.queuedPrompt)
              )
                throw new Error("context.queuedPrompt is of wrong type");

              const recipients = [Recipient.RecipientModel];
              deps.onSendServerWSMessage(
                Commands.ThreadMessageCmdKeyV1,
                recipients,
                encodeMessageCmdParams({
                  messageContent: encodeBase64URI(
                    JSON.stringify(context.queuedPrompt.knowledge),
                  ),
                  messageKind: ThreadMessageKinds.MessageKindKnowledgeChangeV1,
                  recipients,
                  threadId: context.threadId,
                }),
              );
            },
            assign({
              queuedPrompt: undefined,
            }),
          ],
        },
      },
      ready: {
        on: {
          "threads.receive": {
            guard: ({ context, event }) => {
              if (isMessagePrompt(event.message)) return true;
              return (
                "threadId" in event.message &&
                context.threadId === event.message.threadId
              );
            },
            actions: [
              assign({
                label: ({ event, context }) => {
                  if (isMessageTextV1(event.message)) {
                    return event.message.label ?? context.label;
                  }
                  return context.label;
                },
                messages: ({ event, context }) => {
                  const exists = context.messages.findIndex(
                    (m) => m.messageId === event.message.messageId,
                  );
                  if (exists === -1)
                    return [...context.messages, event.message];
                  const m = [...context.messages];
                  m.splice(exists, 1, event.message);
                  return m;
                },
                activeAssets: ({ event, context }) => {
                  if (!isMessageKnowledgeV1(event.message))
                    return context.activeAssets;

                  const { added, removed } =
                    event.message.knowledgeFull.assetContext;

                  let active = [...(context.activeAssets || [])];

                  added.forEach((a) => {
                    deps.knowledgeService.setAssetsCache("byId", a.id, a);
                    const index = active.findIndex((b) => b.id === a.id);

                    if (index === -1) {
                      active.push(a);
                    } else {
                      active.splice(index, 1, a);
                    }
                  });

                  removed.forEach((r) => {
                    deps.knowledgeService.setAssetsCache("byId", r.id, r);
                    active = active.filter((a) => a.id !== r.id);
                  });

                  return active
                    .map(
                      // biome-ignore lint/style/noNonNullAssertion: <explanation>
                      (a) => deps.knowledgeService.assetsCache.byId[a.id]!,
                    )
                    .filter((a) => !!a);
                },
              }),
              // Running this after the other actions so that we have access to the new messages in the context
              assign({
                computed: ({ context }) => computeThreadData(context.messages),
              }),
            ],
          },
          "threads.sendPrompt": [
            {
              target: "sendingPrompt",
              guard: ({ context }) => context.threadId !== null,
              actions: assign({
                queuedPrompt: ({ event }) => ({
                  kind: event.message.kind,
                  prompt: event.message,
                  workingCtx: workingContext(),
                }),
              }),
            },
          ],
          "threads.sendKnowledgeChange": [
            {
              target: "sendingKnowledgeChange",
              guard: ({ context }) => context.threadId !== null,
              actions: assign({
                queuedPrompt: ({ event }) => ({
                  kind: ThreadMessageKinds.MessageKindKnowledgeChangeV1,
                  knowledge: event.message,
                  workingCtx: workingContext(),
                }),
              }),
            },
          ],
        },
      },
      failed: {},
    },
  });
};

import { Client, Server } from "@repo/logger";
import { useActor } from "@xstate/solid";
import { createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import { isServer } from "solid-js/web";
import { useDeviceService } from "~/domains/device/service/deviceService";
import { dictionary as defaultDictionary } from "~/domains/i18n/dictionary/en";
import { localeMachine } from "~/domains/i18n/machine/localeMachine";
import { useLocaleService } from "~/domains/i18n/service/localeService";
import { newIdentityMachine } from "~/domains/identity/machines";
import { useIdentityService } from "~/domains/identity/service";
import { useThreadService } from "~/domains/threads/service";
import type { Services, Wire } from "./types";
import { useEnvironmentService } from "~/domains/environment";
import { init, isThreadMessage } from "@repo/client";
import { useWebsocketService } from "~/domains/ws/service/wsService";
import { newOnReceiveError, newWebsocketMachine } from "~/domains/ws/machines";
import { decodeBase64URI } from "@repo/encoding";
import { useExperimentsService } from "~/domains/experiments";
import { useAppLayoutService } from "~/domains/layouts/appLayout";
import { useFeedbackService } from "~/domains/feedback/service/feedbackService";
import { useKnowledgeService } from "~/domains/knowledge/service";
import { useLimitingService } from "~/domains/limiting";

export function newWire(): Wire {
  // TODO: Hydrate the instance ID so we can tell which server/node spawned this instance of the app.
  const logger = isServer ? new Server("_") : new Client("_");
  const [dictionary, setDictionary] = createStore(defaultDictionary);
  const [isDebug, setIsDebug] = createSignal(true);

  const environment = useEnvironmentService({
    isServer: isServer,
    logger,
  });

  init(
    environment.settings.values.apiTarget,
    environment.settings.values.pullRequestId,
  );

  if (!isServer) {
    // @ts-ignore
    window.flag = (s: string) => {
      if (s === "d") {
        setIsDebug(!isDebug());
      }
    };
  }

  const deviceService = useDeviceService({
    logger,
    dictionary,
  });

  const experimentsService = useExperimentsService({
    logger,
    deviceService,
  });

  const localeService = useLocaleService({
    logger,
    actor: useActor(localeMachine),
    setDictionary,
  });

  const identityService = useIdentityService({
    logger,
    actor: useActor(
      newIdentityMachine(logger),
      // _LOG
      //   ? {
      //       inspect: (inspectionEvent) => {
      //         logger.info(
      //           "identityMachineInspector",
      //           "observation",
      //           convertOptionalParams(inspectionEvent),
      //         );
      //       },
      //     }
      //   : {},
    ),
    dictionary,
    deviceService,
    localeService,
  });

  const limitingService = useLimitingService({
    identity: identityService,
    experiments: experimentsService,
  });

  const websocketService = useWebsocketService({
    logger,
    identityService,
    actor: useActor(
      newWebsocketMachine({
        logger,
        onReceiveMessage: (eventKey, message) => {
          if (!eventKey && Number.parseInt(message?.code ?? "999", 10) >= 300) {
            const data = JSON.parse(
              message.data ? decodeBase64URI(message.data) : "{}",
            );
            logger.warn(
              "factory.onReceiveMessage Err",
              `received error message: [${message?.code ?? "-1"}] ${
                message?.message ?? "Unknown error"
              }: ${
                data?.message ?? "No error message encoded within message data"
              }`,
              { message, data },
            );
            return;
          }

          const domain = eventKey.split(".")[0];
          // logger.info(
          //   "factory.onReceiveMessage",
          //   `routing message for ${domain} domain`,
          //   { eventKey, message },
          // );
          switch (domain) {
            case "threads": {
              const decodedMessage = JSON.parse(decodeBase64URI(message.data));
              decodedMessage.eventKey = message.eventKey;
              if (isThreadMessage(decodedMessage)) {
                services.threads.send(
                  services.threads.eventFactory.newThreadsReceiveMessageEvent(
                    decodedMessage,
                  ),
                );
                return;
              }
              break;
            }
            case "error": {
              services.websocket.send(
                newOnReceiveError(JSON.parse(decodeBase64URI(message.data))),
              );
              return;
            }
            default:
              logger.warn(
                "factory.onReceiveMessage",
                `unhandled message: no domain found for ${domain} domain`,
                { eventKey, message },
              );
          }
        },
      }),
    ),
  });

  const appLayoutService = useAppLayoutService();
  const feedbackService = useFeedbackService();
  const knowledgeService = useKnowledgeService({ identityService });

  const services: Services = {
    identity: identityService,
    locale: localeService,
    threads: useThreadService({
      logger,
      websocketService,
      identityService,
      limitingService,
      knowledgeService,
    }),
    device: deviceService,
    environment,
    websocket: websocketService,
    experiments: experimentsService,
    appLayout: appLayoutService,
    feedback: feedbackService,
    knowledge: knowledgeService,
    limiting: limitingService,
  };

  return {
    dict: dictionary,
    dependencies: {
      logger,
    },
    services,
    metadata: {
      isDefault: false,
      createdAt: performance.now(),
    },
    flags: {
      isDebug,
    },
  };
}

import type { Logger } from "@repo/logger";
import type { useActor } from "@xstate/solid";
import { createEffect, createSignal } from "solid-js";
import type { SetStoreFunction } from "solid-js/store";
import type { Dictionary } from "~/domains/i18n/dictionary/en";
import type { LocaleStates } from "~/domains/i18n/machine/localeMachine";
import type { LocaleKey, LocaleStore } from "~/domains/i18n/types/types";

export type LocaleServiceDependencies = {
  actor: ReturnType<typeof useActor>;
  logger: Logger;
  setDictionary: SetStoreFunction<Dictionary>;
};

export const useLocaleService = (deps: LocaleServiceDependencies) => {
  const [locale, setLocale] = createSignal("en" as LocaleKey);
  const [snapshot, send, actor] = deps.actor;
  deps.logger.info("useLocaleService", "service started");

  createEffect(() => {
    const nextLocate = snapshot.context.locale;
    const currentLocate = locale();
    if (nextLocate === currentLocate) return;
    try {
      fetchDictionary(nextLocate).then((d: Dictionary) => {
        deps.setDictionary(d);
      });
      setLocale(nextLocate);
    } catch (e) {
      deps.logger.error(
        "LOCALE_SERVICE_ERROR",
        "useLocaleService",
        "failed to load dictionary",
        e,
      );
    }
  });

  return {
    snapshot,
    send,
    actor,
    locale,
    setLocale,
  } as {
    snapshot: {
      value: LocaleStates;
      context: LocaleStore;
    };
    locale: typeof locale;
    send: typeof send;
    actor: typeof actor;
    setLocale: typeof setLocale;
  };
};

async function fetchDictionary(locale: LocaleKey): Promise<Dictionary> {
  return (await import(`../dictionary/${locale}.ts`)).dictionary;
}

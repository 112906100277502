export function encodeBase64URI(input: string): string {
  const encoder = new TextEncoder();
  return btoa(String.fromCharCode(...encoder.encode(input)));
}

export function decodeBase64URI(input: string): string {
  const decodedData = Uint8Array.from(atob(input), (c) => c.charCodeAt(0));
  const decoder = new TextDecoder();
  return decoder.decode(decodedData);
}
